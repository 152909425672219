
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import { Data } from '@/models/interfaces/Terms'
import ScrollToMixin from '@/mixins/scrollToDiv.ts'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Terms',
  mixins: [ScrollToMixin],
  data: (): Data => {
    return {
      siteDomain: useStore().state.siteSettings.domain ? useStore().state.siteSettings.domain : ''
    }
  },
  setup (): void {
    useMeta({
      title: 'Terms'
    })
  }
})
